import React, {FC} from "react";
import {GMComponent} from "../GoogleMaps/GMComponent";
import {WINERY_LOCATION} from "../../consts/consts";
import {GoogleMapWrapper} from "./GoogleMapWrapper";
import {ContactDetails} from "../ContactDetails";
import {useTranslation} from "react-i18next";

export const GoogleMapContact: FC<{location?: {lat: number, lng: number}, zoom?: number, onlyMap?: boolean}> = ({location = WINERY_LOCATION, zoom = 14, onlyMap}) => {
    const {t} = useTranslation();

    return (
        <GoogleMapWrapper>
            <GMComponent zoom={zoom} initialCenter={location}/>
            {
                !onlyMap &&
                <ContactDetails address={t('CONTACT.ADDRESS')}
                                phones={[t('CONTACT.TEL3')]}
                                email={t('CONTACT.EMAIL')}
                />
            }
        </GoogleMapWrapper>
    )
};